@import 'mixins';

.title {
  &-content {
    display: flex;
    align-items: center;
    &__save {
      @extend %control_button;
      width: 20px;
      height: 20px;
      background: url(/images/save_menu.png) no-repeat;
      background-size: 20px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
    &__back {
      @extend %control_button;
      width: 1.2em;
      height: 1.5em;
      background-size: contain;
      background: url(/images/back.png) no-repeat;
      margin-right: 15px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
    &__title {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
    }
    &__name {
      color: #303030;
      font-weight: 400;
      margin-right: 20px;
    }
    &__add {
      @extend %control_button;
      background: url(/images/add.png) no-repeat;
      width: 1.5em;
      height: 1.5em;
      background-size: contain;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
  }
}