@import "mixins";

.tail {
  &-dish {
    margin-top: 30px;
    &__property {
      padding-top: 20px;
      border-top: 1px dashed #ccc;
      margin-bottom: 20px;
    }
  }
}