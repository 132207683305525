@import "mixins";

.step {
  margin-top: 20px;
  display: grid;
  //grid-template-columns: repeat(5, 1fr);
  grid-template-areas: "label label label label label" "cover guide guide guide guide" "cover hint hint hint hint" "cover empt empt empt delete";
  justify-content: space-between;

  &__add {
    @extend %dark__control;
  }
  &__delete {
    margin-top: 16px;
    float: right;
    grid-area: delete;
    @extend %dark__control;
    &_disabled {
      @extend %disabled__control;
    }
  }
  &__guide {
    grid-area: guide;
  }
  &__label {
    grid-area: label;
  }
  &__cover {
    grid-area: cover;
  }
  &__hint {
    grid-area: hint;
  }
}
