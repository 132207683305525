.property {
  &-dish {
    &__weight {
      display: grid;
      grid-template-columns: 1fr 1fr ;
      grid-gap: 30px;
    }
    &__portions {
      display: flex;
    }
    &__time {
      display: flex;
    }
    &__description {
      display: grid;
      grid-template-columns: 200px 1fr ;
      grid-gap: 20px;
    }

    &__status {
      margin-top: 20px;
    }
  }
}