.button {
  border: none;
  background-color: #3c4252;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
  font-size: 0.8em;
  min-width: 88px;
  min-height: 36px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  color: white;
  margin-right: 20px;
  &:hover {
    background-color: #2d323e;
  }
  &:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}
