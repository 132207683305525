.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); }
  .modal__main {
    z-index: 99999;
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 40vw;
    min-height: 10vh; }
  .modal__page_active {
    color: red; }

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); }
  .dialog__main {
    position: fixed;
    background: white;
    width: 40vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px; }

.main__menu {
  grid-column: 1 / span 1;
  overflow: hidden; }

.menu-side {
  background-color: #303030;
  color: white;
  padding-top: 20px; }
  .menu-side__title {
    font-weight: 400;
    padding: 15px 0 15px 10px;
    font-size: 1em;
    color: #ccc; }
  .menu-side__navigation {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center; }
    .menu-side__navigation a {
      padding: 15px 0 15px 10px;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
      .menu-side__navigation a:hover {
        background-color: #454545; }
      .menu-side__navigation a.active {
        background-color: #039be5; }
      .menu-side__navigation a:visited {
        color: white; }

.main {
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  background-color: #fafafa; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.main__content {
  grid-column: 2 / -1; }

.content {
  margin: 20px 20px 0 20px; }
  .content__head {
    display: flex;
    justify-content: space-between; }

.search__ingredients_add, .search__recipe_add {
  float: right;
  background: url(/images/add.png) no-repeat;
  background-size: 3em;
  height: 70px;
  width: 70px;
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }

.search-main {
  width: 130px;
  transition: width 0.4s ease-in-out; }
  .search-main:focus {
    width: 200px; }

.search_large {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  padding-left: 15px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  transition: all 0.3s ease; }
  .search_large:focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15); }
  .search_large::-webkit-search-cancel-button {
    appearance: none; }

.search__ingredients {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px; }
  .search__ingredients_add {
    opacity: 0.6; }
    .search__ingredients_add:hover {
      opacity: 0.2; }
    .search__ingredients_add_disabled {
      opacity: 0.4; }
      .search__ingredients_add_disabled:hover {
        opacity: 0.4;
        cursor: auto; }

.search__recipe {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 20px; }
  .search__recipe_add {
    opacity: 0.6; }
    .search__recipe_add:hover {
      opacity: 0.2; }
    .search__recipe_add_disabled {
      opacity: 0.4; }
      .search__recipe_add_disabled:hover {
        opacity: 0.4;
        cursor: auto; }

.textarea,
.input {
  background: none;
  padding: 10px 0 10px 0;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #797979; }
  .textarea:not(:placeholder-shown) ~ .textarea__label,
  .textarea:not(:placeholder-shown) ~ .input__label,
  .input:not(:placeholder-shown) ~ .textarea__label,
  .input:not(:placeholder-shown) ~ .input__label {
    color: #5A667F;
    transform: translateY(-26px) scale(0.75); }
  .textarea:focus ~ .input__label,
  .textarea:focus ~ .textarea__label,
  .input:focus ~ .input__label,
  .input:focus ~ .textarea__label {
    transform: translateY(-26px) scale(0.75); }
  .textarea:hover ~ .textarea__border:before,
  .textarea:hover ~ .input__border:before,
  .input:hover ~ .textarea__border:before,
  .input:hover ~ .input__border:before {
    width: 100%; }
  .textarea:focus ~ .textarea__border:before,
  .textarea:focus ~ .input__border:before,
  .input:focus ~ .textarea__border:before,
  .input:focus ~ .input__border:before {
    width: 100%; }
  .textarea_error,
  .input_error {
    border-color: red; }
    .textarea_error:hover ~ .textarea__border:before,
    .textarea_error:hover ~ .input__border:before,
    .input_error:hover ~ .textarea__border:before,
    .input_error:hover ~ .input__border:before {
      background: red; }
    .textarea_error:focus ~ .textarea__border:before,
    .textarea_error:focus ~ .input__border:before,
    .input_error:focus ~ .textarea__border:before,
    .input_error:focus ~ .input__border:before {
      background: red; }
  .textarea_valid,
  .input_valid {
    border-color: #0d8000; }
    .textarea_valid:hover ~ .textarea__border:before,
    .textarea_valid:hover ~ .input__border:before,
    .input_valid:hover ~ .textarea__border:before,
    .input_valid:hover ~ .input__border:before {
      background: #0d8000; }
    .textarea_valid:focus ~ .textarea__border:before,
    .textarea_valid:focus ~ .input__border:before,
    .input_valid:focus ~ .textarea__border:before,
    .input_valid:focus ~ .input__border:before {
      background: #0d8000; }
  .textarea::placeholder,
  .input::placeholder {
    font-weight: 100;
    font-size: 1em; }
  .textarea__wrapper,
  .input__wrapper {
    position: relative;
    z-index: 0;
    padding-top: 16px; }
  .textarea__error,
  .input__error {
    font-size: 0.9em;
    color: red; }

.textarea {
  height: 80px; }
  .textarea::placeholder {
    font-weight: 100; }

.textarea__label,
.input__label {
  position: absolute;
  top: 27px;
  left: 0;
  color: #9098A9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all .2s ease;
  z-index: -1; }

.textarea__border,
.input__border {
  position: relative;
  display: block;
  width: 100%; }
  .textarea__border:before,
  .input__border:before {
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #4c4c4c;
    transition: 300ms ease all;
    left: 0; }

.input-radio__container {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  user-select: none; }
  .input-radio__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .input-radio__container:hover input ~ .input-radio__checkmark {
    background-color: #ccc; }
  .input-radio__container input:checked ~ .input-radio__checkmark {
    background-color: #2196F3; }
    .input-radio__container input:checked ~ .input-radio__checkmark:after {
      display: block; }

.input-radio__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #dedede;
  border-radius: 50%; }
  .input-radio__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 6px;
    left: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white; }

.input-checkbox__container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  user-select: none; }
  .input-checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .input-checkbox__container:hover input ~ .input-checkbox__checkmark {
    background-color: #ccc; }
  .input-checkbox__container input:checked ~ .input-checkbox__checkmark {
    background-color: #2196F3; }
    .input-checkbox__container input:checked ~ .input-checkbox__checkmark:after {
      display: block; }
  .input-checkbox__container input:disabled ~ .input-checkbox__checkmark {
    background-color: #2c4064; }

.input-checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #dedede; }
  .input-checkbox__checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none; }

.input__submit:hover {
  cursor: pointer; }

.wizard__button_next {
  top: calc(50% + 50px);
  position: fixed;
  right: 10px;
  border: none;
  width: 50px;
  height: 50px;
  background: url(/images/next_button.png) no-repeat;
  opacity: 0.2; }
  .wizard__button_next:hover {
    opacity: 1; }

.wizard {
  height: 70vh;
  width: 70vw; }
  .wizard__title {
    padding: 20px 0 0 30px;
    font-weight: 400;
    font-size: 1.1em;
    min-height: 70px;
    color: white;
    background-color: #3c4252;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .wizard__header {
    height: 30%;
    width: 100%; }
  .wizard__form {
    margin: 0 45px 20px 45px;
    position: relative; }
    .wizard__form-wrapper {
      width: 100%;
      margin-top: 17px;
      height: 50%;
      position: relative;
      overflow: scroll;
      overflow-x: hidden; }
    .wizard__form-footer {
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center; }
  .wizard__button_back {
    top: calc(50% + 50px);
    position: fixed;
    left: -10px;
    border: none;
    width: 50px;
    height: 50px;
    background: url(/images/back_button.png) no-repeat;
    opacity: 0.2; }
    .wizard__button_back:hover {
      opacity: 1; }
  .wizard__button_save {
    background-color: #E3E4E6;
    color: #3C4251;
    border-radius: 3px;
    padding: 5px 20px;
    border: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    .wizard__button_save:disabled {
      background-color: #a1a2a4; }
      .wizard__button_save:disabled:hover {
        background-color: #a1a2a4; }
    .wizard__button_save:hover {
      background-color: #ecedef; }
  .wizard__icon-save {
    background: url(/images/save_form.png) no-repeat;
    background-size: 30px;
    width: 30px;
    height: 30px;
    margin-right: 5px; }

.pagination {
  background-color: white;
  display: flex;
  margin: 10px auto 0;
  width: 30em; }

.pagination .bullet {
  flex: 1;
  position: relative;
  text-align: center;
  transform-style: preserve-3d; }

.pagination .bullet::before,
.pagination .bullet::after {
  content: "";
  display: block;
  position: absolute;
  height: 0.25rem;
  top: 0.7em;
  transform: translate3d(0, 0, -1px); }

.pagination .bullet::before {
  background-color: lightgray;
  width: 100%; }

.pagination .bullet::after {
  background-color: #3c4252;
  transition: opacity 0.25s ease-out;
  opacity: 0;
  width: 100%; }

.pagination .bullet:first-child::before,
.pagination .bullet:first-child::after {
  left: 50%;
  width: 50%; }

.pagination .bullet:last-child::before,
.pagination .bullet:last-child::after {
  width: 50%; }

.pagination .icon {
  background-color: lightgray;
  border-radius: 100%;
  color: transparent;
  cursor: pointer;
  font-size: 0.9em;
  display: inline-block;
  height: 1.6em;
  line-height: 1.5;
  text-align: center;
  transition: background-color 0.25s ease-out;
  width: 1.6em; }

.pagination .text {
  color: lightgray;
  font-size: 0.75em;
  margin-top: 0.5em;
  text-transform: uppercase;
  transition: background-color 0.25s ease-out; }

.pagination .past .icon,
.pagination .current .icon {
  background-color: #3c4252;
  color: white; }

.pagination .past::after,
.pagination .current::after {
  opacity: 1; }

.pagination .past .text,
.pagination .current .text,
.pagination .next .text {
  color: #3c4252; }

.form {
  padding: 10px; }
  .form__btn {
    margin-top: 16px;
    margin-right: 10px; }
    .form__btn:last-child {
      margin-right: 0; }
  .form__title {
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    margin-bottom: 16px; }
  .form__label {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 16px;
    color: #9098A9;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all .2s ease; }

.select {
  background: none;
  padding: 10px 0 10px 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #797979; }
  .select:not(:placeholder-shown) ~ .select__label {
    color: #5A667F;
    transform: translateY(-26px) scale(0.75); }
  .select:focus ~ .select__label {
    transform: translateY(-26px) scale(0.75); }
  .select:focus ~ .select {
    top: -14px;
    font-size: 0.9em;
    color: #555; }
  .select:hover {
    cursor: pointer; }
  .select:hover ~ .select__bar:before {
    width: 100%; }
  .select:focus ~ .select__bar:before {
    width: 100%; }
  .select_valid {
    border-color: green; }
    .select_valid:hover ~ .select__bar:before {
      background: green; }
    .select_valid:focus ~ .select__bar:before {
      background: green; }
  .select_error {
    border-color: red; }
    .select_error:hover ~ .select__bar:before {
      background: red; }
    .select_error:focus ~ .select__bar:before {
      background: red; }
  .select::placeholder {
    font-weight: 100;
    font-size: 1em; }
  .select-recipe {
    padding: 20px 16px; }
    .select-recipe__list {
      margin: 10px 0 0 20px; }
    .select-recipe__item {
      font-size: 1.1em; }
      .select-recipe__item:hover {
        cursor: pointer;
        text-decoration: underline; }
    .select-recipe__weight {
      margin-top: 20px; }
    .select-recipe__title {
      font-size: 1.3em;
      margin-left: 12px; }
    .select-recipe__header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 14px; }
    .select-recipe__btn {
      margin-top: 20px; }
  .select__wrapper {
    position: relative;
    z-index: 0;
    margin-top: 16px; }

.select__label {
  color: #797979;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: -7px;
  top: 10px;
  transition: 300ms ease all; }

.select__bar {
  position: relative;
  display: block;
  width: 100%; }
  .select__bar:before {
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #555;
    transition: 300ms ease all;
    left: 0; }

.button {
  border: none;
  background-color: #3c4252;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
  font-size: 0.8em;
  min-width: 88px;
  min-height: 36px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  color: white;
  margin-right: 20px; }
  .button:hover {
    background-color: #2d323e; }
  .button:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }

.adding-ingredient__first {
  display: grid;
  grid-gap: 20px;
  grid-template-areas: "title title title title" "cover cover icon icon" "cover cover icon icon" "name name name name" "extraName extraName extraName extraName" "calories grams grams measure" "cellulose proteins fats carbohydrates" "barcode barcode barcode barcode" "manufacturer manufacturer manufacturer manufacturer" "description description description description" "extraDescription extraDescription extraDescription extraDescription"; }
  .adding-ingredient__first_title {
    grid-area: title; }

.adding-ingredient__name {
  grid-area: name; }

.adding-ingredient__extraName {
  grid-area: extraName; }

.adding-ingredient__calories {
  grid-area: calories; }

.adding-ingredient__cellulose {
  grid-area: cellulose; }

.adding-ingredient__measure {
  grid-area: measure; }

.adding-ingredient__grams {
  grid-area: grams; }

.adding-ingredient__proteins {
  grid-area: proteins; }

.adding-ingredient__fats {
  grid-area: fats; }

.adding-ingredient__carbohydrates {
  grid-area: carbohydrates; }

.adding-ingredient__description {
  grid-area: description; }

.adding-ingredient__extraDescription {
  grid-area: extraDescription; }

.adding-ingredient__cover {
  grid-area: cover; }

.adding-ingredient__icon {
  grid-area: icon; }

.adding-ingredient__title {
  grid-column: 1 / -1; }

.adding-ingredient__manufacturer {
  grid-area: manufacturer; }

.adding-ingredient__barcode {
  grid-area: barcode; }

.adding-ingredient__second {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr; }

.adding-ingredient__label {
  grid-column: 1 / span 2; }

.adding-ingredient__third {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(7, 1fr); }

.adding-material__main {
  display: grid;
  grid-gap: 25px; }

.adding-menu__main {
  display: grid;
  grid-gap: 25px; }

.adding-menu__meals {
  margin-bottom: 25px; }

.adding-meals__title {
  margin-bottom: 14px;
  font-size: 1.2em; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.recipe__ingredient {
  display: grid;
  grid-template-columns: 100px auto 50px 15px 50px;
  grid-gap: 20px;
  align-items: center;
  /*flex-flow: nowrap;

    justify-content: space-between;*/ }
  .recipe__ingredient_delete {
    background: url(/images/delete_mark.png) no-repeat;
    background-size: 3em;
    height: 40px;
    width: 40px;
    border: none;
    opacity: 0.2;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
    .recipe__ingredient_delete:hover {
      opacity: 1; }

.recipe__select {
  width: 70vw;
  height: 60vh;
  overflow: auto;
  overflow-x: hidden; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.youtube {
  margin: 25px; }
  .youtube_add {
    position: relative;
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 24px;
    border: none;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    background-color: #3c4252;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500; }
    .youtube_add:hover {
      background-color: #2d323e; }
    .youtube_add:active {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
    .youtube_add[tooltip]:after {
      bottom: -35px;
      left: -35px;
      padding: 7px;
      position: absolute;
      font-size: 11px;
      background-color: #666;
      color: white;
      content: attr(tooltip);
      opacity: 0;
      width: 150px;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
    .youtube_add[tooltip]:hover:after {
      opacity: 0.8; }
    .youtube_add_img {
      position: relative;
      width: auto;
      height: 56px; }
      .youtube_add_img[tooltip]:after {
        bottom: -35px;
        left: -35px;
        text-align: center;
        padding: 7px;
        position: absolute;
        font-size: 11px;
        background-color: #666;
        color: white;
        content: attr(tooltip);
        opacity: 0;
        width: 150px;
        transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
      .youtube_add_img[tooltip]:hover:after {
        opacity: 0.8; }
      .youtube_add_img img {
        width: auto;
        height: 100%; }
  .youtube_svg {
    fill: white;
    width: 24px;
    height: 55px; }
  .youtube__buttons {
    margin-top: 30px; }

.dropdownsearch {
  position: relative; }
  .dropdownsearch__wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%; }
  .dropdownsearch__select {
    position: absolute;
    z-index: 998;
    background-color: white;
    top: 40px;
    height: 100px;
    width: 400px;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .dropdownsearch__option:hover {
    cursor: pointer;
    background-color: #b7bfd0; }

.calc__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.step {
  margin-top: 20px;
  display: grid;
  grid-template-areas: "label label label label label" "cover guide guide guide guide" "cover hint hint hint hint" "cover empt empt empt delete";
  justify-content: space-between; }
  .step__delete {
    margin-top: 16px;
    float: right;
    grid-area: delete; }
  .step__guide {
    grid-area: guide; }
  .step__label {
    grid-area: label; }
  .step__cover {
    grid-area: cover; }
  .step__hint {
    grid-area: hint; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.menu-edit__list {
  display: flex;
  flex-flow: nowrap; }

.menu-edit__link {
  padding: 0 10px;
  width: 150px;
  text-align: center; }

.menu__ingredient {
  display: flex;
  flex-flow: nowrap;
  align-items: center; }
  .menu__ingredient_name {
    margin-right: 20px; }
  .menu__ingredient_delete {
    background: url(/images/delete_mark.png) no-repeat;
    background-size: 3em;
    width: 50px;
    height: 40px;
    opacity: 0.6; }
    .menu__ingredient_delete:hover {
      opacity: 0.3; }
    .menu__ingredient_delete_disabled {
      opacity: 0.4; }
      .menu__ingredient_delete_disabled:hover {
        opacity: 0.4;
        cursor: auto; }

.menu-month__picker {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  max-width: 400px; }

.menu-month__list {
  display: flex;
  width: 100%;
  justify-content: space-around; }

.menu-month__item {
  padding: 0 10px; }
  .menu-month__item_active {
    color: red; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.dish {
  box-sizing: border-box;
  padding: 20px 30px 20px 20px;
  border: 1px solid #afafaf;
  margin-top: 16px;
  background-color: #ffffff;
  display: grid;
  grid-gap: 20px; }
  .dish:nth-child(2n+1) {
    background-color: #eeeeee; }
    .dish:nth-child(2n+1) .table-ingredient__general:nth-child(odd) {
      background-color: #dddddd; }
  .dish__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  .dish__tech {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px; }
  .dish__delete {
    background-size: cover;
    height: 2em;
    width: 2em;
    float: right; }
  .dish__btn {
    margin-top: 20px;
    margin-right: 20px;
    width: 200px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.list {
  display: flex;
  justify-content: flex-start;
  flex-flow: nowrap;
  align-items: center;
  margin-bottom: 5px;
  height: 30px; }
  .list-controls__btn {
    margin-right: 20px;
    width: 100%; }
    .list-controls__btn:last-child {
      margin-right: 0; }
  .list__name {
    margin-right: 15px;
    font-size: 18px; }
  .list__ingredients_add {
    float: right;
    margin-top: 20px;
    margin-right: 0; }
  .list__ingredients_list {
    width: 100%;
    float: left; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.table {
  border-spacing: 0;
  margin-top: 20px;
  width: 100%; }
  .table td,
  .table th {
    height: 50px;
    min-width: 30px;
    padding-left: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd; }
  .table th {
    font-weight: normal;
    font-size: 1.1em; }
  .table-ingredient__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    word-break: break-word;
    padding: 10px 0; }
  .table-ingredient__toggler {
    background: url(/images/down-arrow.png) no-repeat;
    background-size: 16px;
    width: 20px;
    height: 13px;
    opacity: 0.6;
    margin-left: 10px; }
    .table-ingredient__toggler:hover {
      opacity: 0.3; }
  .table-ingredient__dashboard {
    position: absolute;
    padding: 10px;
    background-color: #fefefe;
    border: 1px solid #ccc;
    z-index: 999; }
  .table-ingredient__general {
    background-color: #eeeeee; }
  .table-month {
    margin-top: 30px; }
    .table-month td,
    .table-month th {
      border: 1px solid #ddd; }
    .table-month th {
      padding-bottom: 10px; }
    .table-month__day {
      text-decoration: underline; }
      .table-month__day:hover {
        text-decoration: none; }
  .table-day {
    height: 50px; }
    .table-day__list {
      min-height: 50px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap; }
  .table-list {
    width: 100%;
    margin-top: 20px; }
    .table-list__material .table-list__row {
      grid-template-columns: 2fr 2fr 1fr 1fr 2fr 1fr; }
    .table-list__comment .table-list__row {
      grid-template-columns: 1fr 2fr 5fr 2fr 3fr 1fr; }
    .table-list__account .table-list__row {
      grid-template-columns: 2fr 2fr 3fr 1fr 2fr 1fr; }
    .table-list__profile .table-list__row {
      grid-template-columns: 4fr 4fr 2fr 1fr; }
    .table-list__admin .table-list__row {
      grid-template-columns: 2fr 1fr 1fr 2fr 2fr; }
    .table-list__row {
      display: grid;
      min-width: 80px;
      text-align: left;
      align-items: center;
      grid-gap: 20px;
      padding: 10px 0 10px 10px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      word-break: break-word; }
      .table-list__row_menu {
        grid-template-columns: 1fr 1fr 1fr 170px 120px; }
      .table-list__row_ingredient {
        grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 170px 120px; }
      .table-list__row_recipe {
        grid-template-columns: 1fr 170px 180px 120px; }
      .table-list__row_recipe_select {
        grid-template-columns: 1fr 1fr; }
        .table-list__row_recipe_select:hover {
          text-decoration: underline;
          cursor: pointer; }
      .table-list__row_head {
        font-size: 1.1em;
        border-bottom: 1px solid #ddd; }
      .table-list__row:hover {
        background-color: #EEEEEE; }
    .table-list__date {
      font-size: 0.9em;
      color: #6a6a6a; }
    .table-list__name_link {
      transition: text-decoration 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
      .table-list__name_link:hover {
        text-decoration: underline; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.picker {
  position: relative;
  width: 150px; }
  .picker__input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    z-index: -1; }
  .picker__button {
    width: 152px; }
  .picker__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .picker__preview {
    position: relative;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #7e8495;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .picker__preview_fullscreen {
      position: absolute;
      top: -50px;
      left: -50px;
      width: 290px;
      height: 290px;
      background-size: contain;
      background-repeat: no-repeat;
      border: 1px solid #4c4c4c;
      z-index: 999;
      background-color: white; }

.first-recipe {
  display: grid;
  grid-template-columns: 152px 1fr;
  grid-gap: 25px; }
  .first-recipe__description, .first-recipe__techComment, .first-recipe__title {
    grid-column: 1 / -1; }
  .first-recipe__youtube {
    grid-column: 1 / span 1; }
  .first-recipe__name {
    grid-column: 2 / span 1; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.title-content {
  display: flex;
  align-items: center; }
  .title-content__save {
    width: 20px;
    height: 20px;
    background: url(/images/save_menu.png) no-repeat;
    background-size: 20px;
    opacity: 0.6; }
    .title-content__save:hover {
      opacity: 0.3; }
  .title-content__back {
    width: 1.2em;
    height: 1.5em;
    background-size: contain;
    background: url(/images/back.png) no-repeat;
    margin-right: 15px;
    opacity: 0.6; }
    .title-content__back:hover {
      opacity: 0.3; }
  .title-content__title {
    display: flex;
    flex-flow: nowrap;
    align-items: center; }
  .title-content__name {
    color: #303030;
    font-weight: 400;
    margin-right: 20px; }
  .title-content__add {
    background: url(/images/add.png) no-repeat;
    width: 1.5em;
    height: 1.5em;
    background-size: contain;
    opacity: 0.6; }
    .title-content__add:hover {
      opacity: 0.3; }

.item-calc {
  margin-right: 15px; }
  .item-calc__value {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    min-height: 32px;
    border-radius: 2px;
    text-align: center;
    font-size: 1em;
    line-height: 1.8em; }
    .item-calc__value_short {
      background-color: inherit;
      color: black;
      font-weight: bold;
      font-size: 0.9em; }
  .item-calc__name {
    padding: 0 16px;
    text-align: center;
    font-size: 0.8em;
    font-weight: 400; }
    .item-calc__name_short {
      padding: 0;
      line-height: 2em;
      margin-right: 5px; }
  .item-calc_short {
    display: flex;
    margin-right: 5px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.navigation-edit {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  margin-top: 16px; }
  .navigation-edit__item {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #797979;
    border-right: 1px solid #797979;
    box-sizing: border-box; }
    .navigation-edit__item:hover, .navigation-edit__item:active {
      cursor: pointer;
      border-bottom: 2px solid #333; }
    .navigation-edit__item:last-child {
      border-right: none; }
    .navigation-edit__item_active {
      border-bottom: 2px solid #333; }

.portions-dish__input {
  padding-right: 100px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.tail-dish {
  margin-top: 30px; }
  .tail-dish__property {
    padding-top: 20px;
    border-top: 1px dashed #ccc;
    margin-bottom: 20px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.cookingSteps-dish {
  margin: 20px 0; }
  .cookingSteps-dish__btn {
    margin-top: 20px;
    width: 100%; }
  .cookingSteps-dish__step {
    margin-top: 20px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.week-edit {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px; }
  .week-edit__list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  .week-edit__control {
    margin-top: -3px;
    opacity: 0.6; }
    .week-edit__control:hover {
      opacity: 0.3; }
    .week-edit__control_prev {
      background: url(/images/back_button.png) no-repeat;
      width: 1.7em;
      height: 1.7em;
      background-size: contain; }
    .week-edit__control_next {
      background: url(/images/next_button.png) no-repeat;
      width: 1.7em;
      height: 1.7em;
      background-size: contain; }
  .week-edit__item {
    padding: 0 10px; }
    .week-edit__item_active {
      color: red; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.controls-dish {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 16px; }
  .controls-dish__add {
    background: url(/images/add.png) no-repeat;
    background-size: contain;
    width: 2.3em;
    height: 2.3em; }
  .controls-dish__load {
    background: url(/images/save.png) no-repeat;
    width: 2.3em;
    height: 2.3em;
    background-size: contain; }

.controls-day {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 10px 0; }
  .controls-day__add {
    background: url(/images/add.png) no-repeat;
    width: 1.7em;
    height: 1.7em;
    background-size: contain;
    margin-right: 20px;
    opacity: 0.6; }
    .controls-day__add:hover {
      opacity: 0.3; }
  .controls-day__load {
    background: url(/images/save.png) no-repeat;
    width: 1.7em;
    height: 1.7em;
    background-size: contain;
    opacity: 0.6; }
    .controls-day__load:hover {
      opacity: 0.3; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.day-recipe {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 0; }
  .day-recipe__title {
    width: 100%; }
  .day-recipe__link {
    background: url(/images/loupe.png) no-repeat;
    background-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 0.6; }
    .day-recipe__link:hover {
      opacity: 0.3; }
  .day-recipe__delete {
    background: url(/images/delete.png) no-repeat;
    background-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 0.6; }
    .day-recipe__delete:hover {
      opacity: 0.3; }
  .day-recipe__controls {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between; }
  .day-recipe_approved {
    background-color: #ddf8fb; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.confirm {
  padding: 10px;
  max-height: 100px; }
  .confirm__btngroup {
    display: flex;
    justify-content: center; }
  .confirm__btn {
    margin-top: 20px; }
    .confirm__btn_confirm {
      margin-right: 20px; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.node-tree {
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  padding: 5px 0; }
  .node-tree__expand {
    width: 1.1em;
    height: 1.1em;
    background-size: contain;
    margin-top: 3px;
    margin-right: 5px;
    border: none;
    background-color: inherit; }
    .node-tree__expand_open {
      background-image: url(/images/minus.png); }
    .node-tree__expand_close {
      background-image: url(/images/plus.png); }
  .node-tree__controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 200px;
    top: 0;
    left: 25px;
    background-color: #fefefe;
    border: 1px solid #ddd;
    z-index: 1; }
  .node-tree__content {
    min-height: 18px; }
  .node-tree__icon {
    width: 50px;
    height: 50px;
    margin: 0 14px; }
  .node-tree__img {
    object-fit: cover;
    height: 100%;
    width: 100%; }
  .node-tree__btn {
    background-color: inherit;
    border: none;
    padding: 5px;
    font-size: 0.9em;
    width: 100%; }
    .node-tree__btn:hover {
      text-decoration: underline; }
  .node-tree_picked {
    background-color: #c5fbf4; }

.tree {
  padding: 15px 0;
  border-bottom: 1px solid #bfc8d9;
  font-size: 1.1em; }
  .tree:last-child {
    border-bottom: none; }
  .tree__container {
    margin-left: 23px; }

.container-filter__item {
  margin-top: 10px;
  border-bottom: 1px solid #dedede; }
  .container-filter__item:last-child {
    border-bottom: none; }

.container-filter__list {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column; }

.youtube__buttons_close, .youtube__buttons_add, .list__ingredients_add {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px; }
  .youtube__buttons_close:hover, .youtube__buttons_add:hover, .list__ingredients_add:hover {
    background-color: rgba(60, 66, 82, 0.08); }

.form__btn, .step__add, .step__delete, .dish__btn, .list-controls__btn, .picker__button, .cookingSteps-dish__btn, .confirm__btn {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300; }
  .form__btn:hover, .step__add:hover, .step__delete:hover, .dish__btn:hover, .list-controls__btn:hover, .picker__button:hover, .cookingSteps-dish__btn:hover, .confirm__btn:hover {
    background-color: rgba(60, 66, 82, 0.92); }
  .form__btn:disabled, .step__add:disabled, .step__delete:disabled, .dish__btn:disabled, .list-controls__btn:disabled, .picker__button:disabled, .cookingSteps-dish__btn:disabled, .confirm__btn:disabled {
    background-color: #c4c4c4; }

.step__delete_disabled, .dish__btn_disabled, .picker__button_disabled, .cookingSteps-dish__btn_disabled {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300; }
  .step__delete_disabled:hover, .dish__btn_disabled:hover, .picker__button_disabled:hover, .cookingSteps-dish__btn_disabled:hover {
    background-color: #d1d1d1;
    cursor: auto; }

.dish__delete, .tags-item__btn_delete, .menu__ingredient_delete, .table-ingredient__toggler, .title-content__save, .title-content__back, .title-content__add, .week-edit__control, .controls-day__add, .controls-day__load, .day-recipe__link, .day-recipe__delete {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit; }

.dish__delete, .tags-item__btn_delete {
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6; }
  .dish__delete:hover, .tags-item__btn_delete:hover {
    opacity: 0.3; }

.menu-edit__link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .menu-edit__link:hover {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black; }
  .menu-edit__link::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; }

.controls-dish__add, .controls-dish__load {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .controls-dish__add:hover, .controls-dish__load:hover {
    opacity: 0.3; }

.tags-main__header {
  margin-bottom: 20px; }

.tags__list {
  display: flex; }

.tags-item {
  padding: 5px 10px; }
  .tags-item__wrapper {
    display: inline-flex;
    padding: 3px 5px 3px 10px;
    border: 1px solid #7e8495;
    border-radius: 20px; }
  .tags-item__title {
    font-size: 1.1em; }
  .tags-item__btn {
    margin-left: 5px; }
    .tags-item__btn_delete {
      margin-top: -1px;
      width: 24px;
      height: 24px;
      background-size: 24px; }

.tags-tree__checkbox {
  margin-left: 8px;
  margin-top: -3px; }

.tags-search {
  padding: 20px; }
  .tags-search__title {
    margin-top: 20px;
    font-size: 1.3em;
    text-align: center; }
  .tags-search__item {
    margin-top: 15px;
    margin-right: 10px; }
  .tags-search__list {
    display: flex;
    flex-wrap: wrap; }

.property-dish__weight {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px; }

.property-dish__portions {
  display: flex; }

.property-dish__time {
  display: flex; }

.property-dish__description {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 20px; }

.property-dish__status {
  margin-top: 20px; }

.image-fullscreen {
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw; }
  .image-fullscreen__wrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6); }

.dropzone-tags__wrapper {
  background: white;
  min-height: 50px;
  max-width: 320px;
  padding: 12px 40px;
  border: 1.5px dashed #5a6070;
  display: flex;
  justify-content: center;
  align-items: center; }
  .dropzone-tags__wrapper_active {
    background: linear-gradient(45deg, #5a6070 2.38%, #ffffff 2.38%, #ffffff 50%, #5a6070 50%, #5a6070 52.38%, #ffffff 52.38%, #ffffff 100%);
    background-size: 29.70px 29.70px; }
  .dropzone-tags__wrapper:hover {
    cursor: pointer; }

html,
body {
  height: 100vh; }

body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

li {
  list-style: none; }

ul {
  padding: 0;
  margin: 0; }

a {
  color: inherit;
  text-decoration: none; }

button {
  cursor: pointer; }

button,
select,
textarea,
input {
  outline: none; }

textarea {
  resize: none; }

#root {
  height: 100vh; }

.wrapper {
  height: 100vh;
  display: grid; }

.display_none {
  display: none; }

textarea.error {
  border-color: red; }

select.error {
  border-color: red; }
