.dropdownsearch {
  position: relative;
  &__wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%;
  }
  &__select {
    position: absolute;
    z-index: 998;
    background-color: white;
    top: 40px;
    height: 100px;
    width: 400px;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  &__option {
    &:hover {
      cursor: pointer;
      background-color: #b7bfd0;
    }
  }
}
