$wizard-color: #3c4252;

%button_next {
  top: calc(50% + 50px);
  position: fixed;
  right: 10px;
  border: none;
  width: 50px;
  height: 50px;
  background: url(/images/next_button.png) no-repeat;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
}

.wizard {
  height: 70vh;
  width: 70vw;
  &__title {
    padding: 20px 0 0 30px;
    font-weight: 400;
    font-size: 1.1em;
    min-height: 70px;
    color: white;
    background-color: $wizard-color;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  &__header {
    height: 30%;
    width: 100%;
  }
  &__form {
    margin: 0 45px 20px 45px;
    position: relative;
    &-wrapper {
      //top: 200px;
      width: 100%;
      margin-top: 17px;
      height: 50%;
      position: relative;
      overflow: scroll;
      overflow-x: hidden;
    }
    &-footer {
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__button_back {
    top: calc(50% + 50px);
    position: fixed;
    left: -10px;
    border: none;
    width: 50px;
    height: 50px;
    background: url(/images/back_button.png) no-repeat;
    opacity: 0.2;
    &:hover {
      opacity: 1;
    }
  }
  &__button_next {
    @extend %button_next;
  }
  &__button_save {
    //@extend %button_next;
    //background: url(/images/save.png) no-repeat;
    //background-size: 2.5em;
    background-color: #E3E4E6;
    color: #3C4251;
    border-radius: 3px;
    padding: 5px 20px;
    border: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    &:disabled {
      background-color: #a1a2a4;
      &:hover {
        background-color: #a1a2a4;
      }
    }
    &:hover {
      background-color: #ecedef;
    }
  }
  &__icon {
    &-save {
      background: url(/images/save_form.png) no-repeat;
      background-size: 30px;
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
}

.pagination {
  background-color: white;
  display: flex;
  margin: 10px auto 0;
  width: 30em;
}
.pagination .bullet {
  flex: 1;
  position: relative;
  text-align: center;
  transform-style: preserve-3d;
}
.pagination .bullet::before,
.pagination .bullet::after {
  content: "";
  display: block;
  position: absolute;
  height: 0.25rem;
  top: 0.7em;
  transform: translate3d(0, 0, -1px);
}
.pagination .bullet::before {
  background-color: lightgray;
  width: 100%;
}
.pagination .bullet::after {
  background-color: $wizard-color;
  transition: opacity 0.25s ease-out;
  opacity: 0;
  width: 100%;
}

.pagination .bullet:first-child::before,
.pagination .bullet:first-child::after {
  left: 50%;
  width: 50%;
}
.pagination .bullet:last-child::before,
.pagination .bullet:last-child::after {
  width: 50%;
}

.pagination .icon {
  background-color: lightgray;
  border-radius: 100%;
  color: transparent;
  cursor: pointer;
  font-size: 0.9em;
  display: inline-block;
  height: 1.6em;
  line-height: 1.5;
  text-align: center;
  transition: background-color 0.25s ease-out;
  width: 1.6em;
}
.pagination .text {
  color: lightgray;
  font-size: 0.75em;
  margin-top: 0.5em;
  text-transform: uppercase;
  transition: background-color 0.25s ease-out;
}
.pagination .past .icon,
.pagination .current .icon {
  background-color: $wizard-color;
  color: white;
}

.pagination .past::after,
.pagination .current::after {
  opacity: 1;
}

.pagination .past .text,
.pagination .current .text,
.pagination .next .text {
  color: $wizard-color;
}
