@import "mixins";

.navigation {
  &-edit {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    margin-top: 16px;
    &__item {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #797979;
      border-right: 1px solid #797979;
      box-sizing: border-box;
      &:hover,
      &:active {
        cursor: pointer;
        border-bottom: 2px solid #333;
      }
      &:last-child {
        border-right: none;
      }
      &_active {
        border-bottom: 2px solid #333;
      }
    }
  }
}