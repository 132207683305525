@import "mixins";

.day {
  &-recipe {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 0;
    &__title {
      width: 100%;
    }
    &__link {
      @extend %control_button;
      background: url(/images/loupe.png) no-repeat;
      background-size: 25px;
      width: 25px;
      height: 25px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
    &__delete {
      @extend %control_button;
      background: url(/images/delete.png) no-repeat;
      background-size: 25px;
      width: 25px;
      height: 25px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
    &__controls {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
    &_approved {
      background-color: #ddf8fb;
    }
  }
}