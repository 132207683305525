@import "mixins";

.header {
  position: fixed;
  display: flex;
  z-index: 999;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  height: 64px;
  line-height: 60px;
  width: 100%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  &__title {
    font-weight: 400;
    color: #707070;
    text-transform: uppercase;
    padding-left: 20px;
  }
  &-edit {
    position: sticky;
    top: 63px;
    z-index: 998;
    background-color: #fafafa;
    &__day {
      display: grid;
      grid-template-columns: 200px 300px 1fr;
      align-items: center;
    }
    &__week {
      display: grid;
      align-items: center;
      grid-template-columns: 200px 1fr;
    }
    &__calories {
      margin-right: 30px;
    }
  }
  &__button {
    @extend %dark__control;
    height: 40px;
    margin-right: 20px;
  }
  &-content {
    &__filter {
      display: grid;
      grid-gap: 20px;
      grid-auto-flow: column;
    }
  }
}
