@import "mixins";

.table {
  border-spacing: 0;
  margin-top: 20px;
  width: 100%;
  td,
  th {
    height: 50px;
    min-width: 30px;
    padding-left: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  th {
    font-weight: normal;
    font-size: 1.1em;
  }
  &-ingredient {
    &__name {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      word-break: break-word;
      padding: 10px 0;
    }
    &__toggler {
      @extend %control_button;
      background: url(/images/down-arrow.png) no-repeat;
      background-size: 16px;
      width: 20px;
      height: 13px;
      opacity: 0.6;
      margin-left: 10px;
      &:hover {
        opacity: 0.3;
      }
    }
    &__dashboard {
      position: absolute;
      padding: 10px;
      background-color: #fefefe;
      border: 1px solid #ccc;
      z-index: 999;
    }
    &__general {
      background-color: #eeeeee;
    }
  }
  &-month {
    margin-top: 30px;
    td,
    th {
      border: 1px solid #ddd;
    }
    th {
      padding-bottom: 10px;
    }
    &__day {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-day {
    height: 50px;
    &__list {
      min-height: 50px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  &-list {
    width: 100%;
    margin-top: 20px;
    &__material &__row {
        grid-template-columns: 2fr 2fr 1fr 1fr 2fr 1fr;
    }
    &__comment &__row {
      grid-template-columns: 1fr 2fr 5fr 2fr 3fr 1fr;
    }
    &__account &__row {
      grid-template-columns: 2fr 2fr 3fr 1fr 2fr 1fr;
    }
    &__profile &__row {
      grid-template-columns: 4fr 4fr 2fr 1fr;
    }
    &__admin &__row {
      grid-template-columns: 2fr 1fr 1fr 2fr 2fr;
    }
    &__row {
      display: grid;
      min-width: 80px;
      text-align: left;
      align-items: center;
      grid-gap: 20px;
      padding: 10px 0 10px 10px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      word-break: break-word;
      &_menu {
        grid-template-columns: 1fr 1fr 1fr 170px 120px;
      }
      &_ingredient {
        grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 170px 120px;
      }
      &_recipe {
        grid-template-columns: 1fr 170px 180px 120px;
      }
      &_recipe_select {
        grid-template-columns: 1fr 1fr;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &_head {
        font-size: 1.1em;
        border-bottom: 1px solid #ddd;
      }
      &:hover {
        background-color: #EEEEEE;
      }
    }
    &__date {
      font-size: 0.9em;
      color: #6a6a6a;
    }
    &__name {
      &_link {
        transition: text-decoration 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}