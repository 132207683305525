@import 'mixins';

.menu {
  &-edit {
    &__list {
      display: flex;
      flex-flow: nowrap;
    }
    &__link {
      padding: 0 10px;
      width: 150px;
      text-align: center;
      @extend %link
    }
  }
  &__ingredient {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    &_name {
      margin-right: 20px;
    }
    &_delete {
      @extend %control_button;
      background: url(/images/delete_mark.png) no-repeat;
      background-size: 3em;
      width: 50px;
      height: 40px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
      &_disabled {
        opacity: 0.4;
        &:hover {
          opacity: 0.4;
          cursor: auto;
        }
      }
    }
  }
  &-month {
    &__picker {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      max-width: 400px;
    }
    &__list {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    &__item {
      padding: 0 10px;
      &_active {
        color: red;
      }
    }
  }
}