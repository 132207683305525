.first {
  &-recipe {
    display: grid;
    grid-template-columns: 152px 1fr;
    grid-gap: 25px;
    &__description,
    &__techComment,
    &__title {
      grid-column: 1 / -1;
    }
    &__youtube {
      grid-column: 1 / span 1;
    }
    &__name {
      grid-column: 2 / span 1;
    }
  }
}