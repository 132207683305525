@import "mixins";

.main__content {
  grid-column: 2 / -1;
}

.content {
  margin: 20px 20px 0 20px;
  &__head {
    display: flex;
    justify-content: space-between;
  }
}
