@import "mixins";

.dish {
  box-sizing: border-box;
  padding: 20px 30px 20px 20px;
  border: 1px solid #afafaf;
  margin-top: 16px;
  background-color: #ffffff;
  display: grid;
  grid-gap: 20px;
  &:nth-child(2n+1) {
    background-color: #eeeeee;
    .table-ingredient{
      &__general:nth-child(odd) {
        background-color: #dddddd;
      }
    }
  }
  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  &__tech {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
  }
  &__delete {
    @extend %control_button_delete;
    background-size: cover;
    height: 2em;
    width: 2em;
    float: right;
  }
  &__btn {
    margin-top: 20px;
    margin-right: 20px;
    @extend %dark__control;
    width: 200px;
    &_disabled {
      @extend %disabled__control;
    }
  }
}