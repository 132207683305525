.dropzone {
  &-tags {
    &__wrapper {
      background: white;
      min-height: 50px;
      max-width: 320px;
      padding: 12px 40px;
      border: 1.5px dashed #5a6070;
      display: flex;
      justify-content: center;
      align-items: center;
      &_active {
        background: linear-gradient(45deg, #5a6070 2.38%, #ffffff 2.38%, #ffffff 50%, #5a6070 50%, #5a6070 52.38%, #ffffff 52.38%, #ffffff 100%);
        background-size: 29.70px 29.70px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}