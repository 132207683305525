%button-add {
  float: right;
  background: url(/images/add.png) no-repeat;
  background-size: 3em;
  height: 70px;
  width: 70px;
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.search {
  &-main {
    width: 130px;
    transition: width 0.4s ease-in-out;
    &:focus {
      width: 200px;
    }
  }
  &_large {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    padding-left: 15px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    background: #fff;
    transition: all 0.3s ease;
    &:focus {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
    }
    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }
  &__ingredients {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    &_add {
      @extend %button-add;
      opacity: 0.6;
      &:hover {
        opacity: 0.2;
      }
      &_disabled {
        opacity: 0.4;
        &:hover {
          opacity: 0.4;
          cursor: auto;
        }
      }
    }
  }
  &__recipe {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 20px;
    &_add {
      @extend %button-add;
      opacity: 0.6;
      &:hover {
        opacity: 0.2;
      }
      &_disabled {
        opacity: 0.4;
        &:hover {
          opacity: 0.4;
          cursor: auto;
        }
      }
    }
  }
}
