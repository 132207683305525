.item {
  &-calc {
    margin-right: 15px;
    &__value {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
      min-height: 32px;
      border-radius: 2px;
      text-align: center;
      font-size: 1em;
      line-height: 1.8em;
      &_short {
        background-color: inherit;
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
        font-size: 0.9em;
      }
    }

    &__name {
      padding: 0 16px;
      text-align: center;
      font-size: 0.8em;
      font-weight: 400;
      &_short {
        padding: 0;
        line-height: 2em;
        margin-right: 5px;
      }
    }
    &_short {
      display: flex;
      margin-right: 5px;
    }
  }
}
