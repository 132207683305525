@import "mixins";

.controls {
  &-dish {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 16px;
    &__add {
      @extend %dish_controls;
      background: url(/images/add.png) no-repeat;
      background-size: contain;
      width: 2.3em;
      height: 2.3em;
    }
    &__load {
      @extend %dish_controls;
      background: url(/images/save.png) no-repeat;
      width: 2.3em;
      height: 2.3em;
      background-size: contain;
    }
  }
  &-day {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 10px 0;
    &__add {
      @extend %control_button;
      background: url(/images/add.png) no-repeat;
      width: 1.7em;
      height: 1.7em;
      background-size: contain;
      margin-right: 20px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
    &__load {
      @extend %control_button;
      background: url(/images/save.png) no-repeat;
      width: 1.7em;
      height: 1.7em;
      background-size: contain;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
    }
  }
}