.main__menu {
  grid-column: 1 / span 1;
  overflow: hidden;
}

.menu-side {
  background-color: #303030;
  color: white;
  padding-top: 20px;
  &__title {
    font-weight: 400;
    padding: 15px 0 15px 10px;
    font-size: 1em;
    color: #ccc;
  }
  &__navigation {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    a {
      padding: 15px 0 15px 10px;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: #454545;
      }

      &.active {
        background-color: #039be5;
      }

      &:visited {
        color: white;
      }
    }
  }
}
