.comments {
  &__wrapper {
    padding: 20px 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 1px solid #ccc;
    border-radius: 9px;
    margin-top: 20px;
  }
  &__content {
    margin-top: 12px;
    overflow-y: auto;
  }
  &__message {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}

.comment {
  &__wrapper {
    margin-top: 14px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 14px;
    border-radius: 12px;
    box-shadow: none;
    width: 70%;
    font-size: 1.2em;
    &:first-child {
      margin-top: 0;
    }
  }
  &__date {
    font-size: 0.85em;
    color: #acacac;
  }
  &__text {
    margin-top: 9px;
  }
  &__header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  &__btn {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}