@import "../../../commons/styles/mixins";

.calorie {
  &__title {
    font-size: 1.2em;
    margin-bottom: 7px;
  }
  &__field {
    margin-right: 30px;
    width: 100%;
  }
  &__wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
  }
  &__btn {
    @extend %dark__control;
  }
}