@import "mixins";

.list {
  display: flex;
  justify-content: flex-start;
  flex-flow: nowrap;
  align-items: center;
  margin-bottom: 5px;
  height: 30px;
  &-controls {
    &__btn {
      @extend %dark__control;
      margin-right: 20px;
      width: 100%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__name {
    margin-right: 15px;
    font-size: 18px;
  }
  &__ingredients {
    &_add {
      @extend %bright__control;
      float: right;
      margin-top: 20px;
      margin-right: 0;
    }
    &_list {
      width: 100%;
      float: left;
    }
  }
}