@import "mixins";

.youtube {
  margin: 25px;
  &_add {
    position: relative;
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 24px;
    border: none;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    background-color: #3c4252;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    &:hover {
      background-color: #2d323e;
    }
    &:active {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
    &[tooltip]:after {
      bottom: -35px;
      left: -35px;
      padding: 7px;
      position: absolute;
      font-size: 11px;
      background-color: #666;
      color: white;
      content: attr(tooltip);
      opacity: 0;
      width: 150px;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &[tooltip]:hover:after {
      opacity: 0.8;
    }
    &_img {
      position: relative;
      width: auto;
      height: 56px;
      &[tooltip]:after {
        bottom: -35px;
        left: -35px;
        text-align: center;
        padding: 7px;
        position: absolute;
        font-size: 11px;
        background-color: #666;
        color: white;
        content: attr(tooltip);
        opacity: 0;
        width: 150px;
        transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      &[tooltip]:hover:after {
        opacity: 0.8;
      }
      & img {
        width: auto;
        height: 100%;
      }
    }
  }
  &_svg {
    fill: white;
    width: 24px;
    height: 55px;
  }
  &__buttons {
    margin-top: 30px;
    &_close {
      @extend %bright__control;
    }
    &_add {
      @extend %bright__control;
    }
  }
}
