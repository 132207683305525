@import "mixins";

.week {
  &-edit {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    &__list {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    &__control {
      @extend %control_button;
      margin-top: -3px;
      opacity: 0.6;
      &:hover {
        opacity: 0.3;
      }
      &_prev {
        background: url(/images/back_button.png) no-repeat;
        width: 1.7em;
        height: 1.7em;
        background-size: contain;
      }
      &_next {
        background: url(/images/next_button.png) no-repeat;
        width: 1.7em;
        height: 1.7em;
        background-size: contain;
      }
    }
    &__item {
      padding: 0 10px;
      &_active {
        color: red;
      }
    }
  }
}