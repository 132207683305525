.preloader {
  width: 128px;
  height: 128px;
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &_small {
    width: 64px;
    height: 64px;
  }
}