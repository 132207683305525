.icon {
  &-picker {
    position: relative;
    width: 100%;
    height: 100%;
    &__preview {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &__label {
      display: flex;
      padding: 12px;
      &:hover {
        cursor: pointer;
      }
      &_fill {
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__action {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}