@import "mixins";

.node {
  &-tree {
    display: flex;
    align-items: center;
    flex-flow: nowrap;
    padding: 5px 0;
    &__expand {
      width: 1.1em;
      height: 1.1em;
      background-size: contain;
      margin-top: 3px;
      margin-right: 5px;
      border: none;
      background-color: inherit;
      &_open {
         background-image: url(/images/minus.png);
       }
      &_close {
        background-image: url(/images/plus.png);
      }
    }
    &__controls {
      position: absolute;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 200px;
      top: 0;
      left: 25px;
      background-color: #fefefe;
      border: 1px solid #ddd;
      z-index: 1;
    }
    &__content {
      min-height: 18px;
    }
    &__icon {
      width: 50px;
      height: 50px;
      margin: 0 14px;
    }
    &__img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    &__btn {
      background-color: inherit;
      border: none;
      padding: 5px;
      font-size: 0.9em;
      width: 100%;
      &:hover {
        text-decoration: underline;
      }
    }
    &_picked {
      background-color: #c5fbf4;
    }
  }
}

.tree {
  padding: 15px 0;
  border-bottom: 1px solid #bfc8d9;
  font-size: 1.1em;
  &:last-child {
    border-bottom: none;
  }
  &__container {
    margin-left: 23px;
  }
}