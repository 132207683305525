@import "mixins";

.cookingSteps {
  &-dish {
    margin: 20px 0;
    &__btn {
      @extend %dark__control;
      margin-top: 20px;
      width: 100%;
      &_disabled{
        @extend %disabled__control;
      }
    }
    &__step {
      margin-top: 20px;
    }
  }
}