$input-width: 320px;
$input-color: #797979;
$hl-color: #4c4c4c;
$hl-color-error: red;
$hl-color-valid: #0d8000;

.textarea,
.input {
  background: none;
  padding: 10px 0 10px 0;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $input-color;

  &:not(:placeholder-shown) {
        ~.textarea__label,
        ~.input__label {
          color: #5A667F;
          transform: translateY(-26px) scale(.75);
        }
      }
  &:focus ~.input__label,
  &:focus ~ .textarea__label
  {
    transform: translateY(-26px) scale(.75);
  }
  &:hover ~ .textarea__border:before,
  &:hover ~ .input__border:before {
    width: 100%;
  }
  &:focus ~ .textarea__border:before,
  &:focus ~ .input__border:before {
    width: 100%;
  }
  &_error {
    border-color: $hl-color-error;
    &:hover ~ .textarea__border:before,
    &:hover ~ .input__border:before {
      background: $hl-color-error;
    }
    &:focus ~ .textarea__border:before,
    &:focus ~ .input__border:before {
      background: $hl-color-error;
    }
  }
  &_valid {
    border-color: $hl-color-valid;
    &:hover ~ .textarea__border:before,
    &:hover ~ .input__border:before {
      background: $hl-color-valid;
    }
    &:focus ~ .textarea__border:before,
    &:focus ~ .input__border:before {
      background: $hl-color-valid;
    }
  }
  &::placeholder {
    font-weight: 100;
    font-size: 1em;
  }
  &__wrapper {
    position: relative;
    z-index: 0;
    padding-top: 16px;
  }
  &__error {
    font-size: 0.9em;
    color: $hl-color-error;
  }
}

.textarea {
  height: 80px;
  &::placeholder {
    font-weight: 100;
  }
}

.textarea__label,
.input__label {
  position: absolute;
  top: 27px;
  left: 0;
  color: #9098A9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all .2s ease;
  z-index: -1;
}

.textarea__border,
.input__border {
  position: relative;
  display: block;
  width: 100%;
  &:before {
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $hl-color;
    transition: 300ms ease all;
    left: 0;
  }
}

.input-radio {
  &__container {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1em;
    user-select: none;
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover input ~ .input-radio__checkmark {
      background-color: #ccc;
    }
    & input:checked ~ .input-radio__checkmark {
      background-color: #2196F3;
      &:after {
        display: block;
      }
    }
  }
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dedede;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 6px;
      left: 7px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: white;
    }
  }
}

.input-checkbox {
  &__container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1em;
    user-select: none;
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover input ~ .input-checkbox__checkmark {
      background-color: #ccc;
    }
    & input:checked ~ .input-checkbox__checkmark {
      background-color: #2196F3;
      &:after {
        display: block;
      }
    }
    & input:disabled ~ .input-checkbox__checkmark {
      background-color: #2c4064;
    }
  }
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dedede;
    &:after {
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.input__submit {
  &:hover {
    cursor: pointer;
  }
}