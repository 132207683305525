@import 'mixins';

.picker {
  position: relative;
  width: 150px;
   &__input {
     width: 1px;
     height: 1px;
     opacity: 0;
     position: absolute;
     overflow: hidden;
     z-index: -1;
   }
  &__button {
    @extend %dark__control;
    width: 152px;
    &_disabled {
      @extend %disabled__control;
    }
  }
   &__label {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
   }
   &__preview {
     position: relative;
     height: 150px;
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
     border: 1px solid #7e8495;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     &_fullscreen {
       position: absolute;
       top: -50px;
       left: -50px;
       width: 290px;
       height: 290px;
       background-size: contain;
       background-repeat: no-repeat;
       border: 1px solid #4c4c4c;
       z-index: 999;
       background-color: rgba(255,255,255,1);
     }
   }
 }


