%bright__control {
  color: #3c4252;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: white;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  margin-right: 30px;
  &:hover {
    background-color: rgba(60, 66, 82, 0.08);
  }
}

%dark__control {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #3c4252;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
  font-weight: 300;
  &:hover {
    background-color: rgba(60, 66, 82, 0.92);
  }
  &:disabled {
    background-color: #c4c4c4;
  }
}

%disabled__control {
  color: white;
  padding: 8px 16px;
  box-sizing: border-box;
  min-height: 36px;
  background-color: #d1d1d1;
  border: none;
  font-size: 1em;
  font-weight: 300;
  &:hover {
    background-color: #d1d1d1;
    cursor: auto;
  }
}

%control_button {
  border: none;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: inherit;
}

%control_button_delete {
  @extend %control_button;
  background-image: url(/images/delete-button.png);
  background-repeat: no-repeat;
  opacity: 0.6;
  &:hover {
    opacity: 0.3;
  }
}

%link {
  cursor: pointer;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover,
  &_active {
    text-shadow: -0.06ex 0 black, 0.06ex 0 black;
  }
  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

%dish_controls {
  border: none;
  opacity: 0.6;
  margin-right: 100px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    opacity: 0.3;
  }
}