.more {
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #dedede;
  }
  &__wrapper {
    position: relative;
    display: flex;
  }
}