@import "mixins";

.confirm {
  padding: 10px;
  max-height: 100px;
  &__btngroup {
    display: flex;
    justify-content: center;
  }
  &__btn {
    @extend %dark__control;
    margin-top: 20px;
    &_confirm {
      margin-right: 20px;
    }
  }
}