.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);

  &__main {
    z-index: 99999;
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 40vw;
    min-height: 10vh;
  }

  &__page {
    &_active {
      color: red;
    }
  }
}
