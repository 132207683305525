.container {
  &-filter {
    &__item {
      margin-top: 10px;
      border-bottom: 1px solid #dedede;
      &:last-child {
        border-bottom: none;
      }
    }
    &__list {
      margin-top: 10px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
}