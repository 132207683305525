@import "mixins";

.tags {
  &-main {
    &__header {
      margin-bottom: 20px;
    }
  }
  &__list {
    display: flex;
  }
  &-item {
    padding: 5px 10px;
    &__wrapper {
      display: inline-flex;
      padding: 3px 5px 3px 10px;
      border: 1px solid #7e8495;
      border-radius: 20px;
    }
    &__title {
      font-size: 1.1em;
    }
    &__btn {
      margin-left: 5px;
      &_delete {
        @extend %control_button_delete;
        margin-top: -1px;
        width: 24px;
        height: 24px;
        background-size: 24px;
      }
    }
  }
  &-tree {
    &__checkbox {
      margin-left: 8px;
      margin-top: -3px;
    }
  }
  &-search {
    padding: 20px;
      &__title {
        margin-top: 20px;
        font-size: 1.3em;
        text-align: center;
      }
      &__item {
        margin-top: 15px;
        margin-right: 10px;
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
      }
  }
}