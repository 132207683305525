.form {
  padding: 10px;
  &__btn {
    @extend %dark__control;
    margin-top: 16px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    margin-bottom: 16px;
  }
  &__label{
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 16px;
    color: #9098A9;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all .2s ease;
  }
  &__border {

  }
}
