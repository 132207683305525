@import 'mixins';

.authorisation {
  width: 30vw;
  &__wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-weight: 400;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 20px;
  }
  &__button {
    @extend %dark__control;
    width: 100%;
    margin-top: 10px;
  }
}