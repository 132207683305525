@import "mixins";

.recipe {
  &__ingredient {
    display: grid;
    grid-template-columns: 100px auto 50px 15px 50px;
    grid-gap: 20px;
    align-items: center;
    /*flex-flow: nowrap;

    justify-content: space-between;*/
    &_delete {
      background: url(/images/delete_mark.png) no-repeat;
      background-size: 3em;
      height: 40px;
      width: 40px;
      border: none;
      opacity: 0.2;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &:hover {
        opacity: 1;
      }
    }
  }
  &__select {
    width: 70vw;
    height: 60vh;
    overflow: auto;
    overflow-x: hidden;
  }
}