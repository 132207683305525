$input-width: 320px;
$input-color: #797979;
$hl-color: #555;
$hl-color-error: red;
$hl-color-valid: green;

.select {
  background: none;
  padding: 10px 0 10px 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $input-color;

  &:not(:placeholder-shown) {
    ~.select__label {
      color: #5A667F;
      transform: translateY(-26px) scale(.75);
    }
  }
  &:focus ~.select__label {
    transform: translateY(-26px) scale(.75);
  }
  &:focus ~ .select {
    top: -14px;
    font-size: 0.9em;
    color: $hl-color;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover ~ .select__bar:before {
    width: 100%;
  }
  &:focus ~ .select__bar:before {
    width: 100%;
  }
  &_valid {
    border-color: $hl-color-valid;
    &:hover ~ .select__bar:before {
      background: $hl-color-valid;
    }
    &:focus ~ .select__bar:before {
      background: $hl-color-valid;
    }
  }
  &_error {
    border-color: $hl-color-error;
    &:hover ~ .select__bar:before {
      background: $hl-color-error;
    }
    &:focus ~ .select__bar:before {
      background: $hl-color-error;
    }
  }
  &::placeholder {
    font-weight: 100;
    font-size: 1em;
  }
  &-recipe {
    padding: 20px 16px;
    &__list {
      margin: 10px 0 0 20px;
    }
    &__item {
      font-size: 1.1em;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &__weight {
      margin-top: 20px;
    }
    &__title {
      font-size: 1.3em;
      margin-left: 12px;
    }
    &__header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 14px;
    }
    &__btn {
      margin-top: 20px;
    }
  }
  &__wrapper {
    position: relative;
    z-index: 0;
    margin-top: 16px;
  }
}

.select__label {
  color: $input-color;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: -7px;
  top: 10px;
  transition: 300ms ease all;
}

.select__bar {
  position: relative;
  display: block;
  width: 100%;
  &:before {
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $hl-color;
    transition: 300ms ease all;
    left: 0;
  }
}

//.select {
//  -webkit-appearance: none;
//  width: 100%;
//  border: 0;
//  font-family: inherit;
//  padding: 12px 0;
//  height: 30px;
//  font-size: 16px;
//  font-weight: 500;
//  border-bottom: 2px solid #C8CCD4;
//  background: none;
//  border-radius: 0;
//  transition: all .3s ease;
//  &:not(:placeholder-shown) {
//    ~.select__label {
//      color: #5A667F;
//      transform: translateY(-26px) scale(.75);
//    }
//  }
//  &:focus {
//    outline: none;
//    ~.select__border,
//    ~.select__label {
//      transform: translateY(-26px) scale(.75);
//    }
//    ~.select__border{
//      transform: scaleX(1);
//    }
//  }
//
//  &__wrapper {
//    position: relative;
//  }
//
//  &__label {
//    position: absolute;
//    top: 16px;
//    left: 0;
//    font-size: 16px;
//    color: #9098A9;
//    font-weight: 500;
//    transform-origin: 0 0;
//    transition: all .2s ease;
//    z-index: -1;
//  }
//
//  &__border {
//    position: absolute;
//    bottom: 0;
//    left: 0;
//    height: 2px;
//    width: 100%;
//    background: $hl-color;
//    transform: scaleX(0);
//    transform-origin: 0 0;
//    transition: all .15s ease;
//  }
//
//  &_valid {
//    ~.select_border {
//      background: $hl-color-valid;
//      transform: scaleX(1);
//    }
//  }
//  &_error {
//    ~.select__border {
//      background: $hl-color-error;
//      transform: scaleX(1);
//    }
//  }
//}
//
//.option {
//  padding: 10px 0 10px 20px;
//}
