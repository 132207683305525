@import "mixins";

.filter {
  &__wrapper {
    padding-top: 3px;
    position: relative;
  }
  &__btn {
    @extend %control_button;
    background: url(/images/filter.png) no-repeat;
    background-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 0.6;
    &:hover {
      opacity: 0.3;
    }
    &_active {
      background: url(/images/filter_active.png) no-repeat;
      background-size: 25px;
      width: 25px;
      height: 25px;
    }
  }
  &__container {
    position: absolute;
    z-index: 1;
    padding: 20px;
    min-width: 250px;
    border-radius: 9px;
    border: 1px solid #bbc4d5;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: whitesmoke;
    right: 10px;
  }
}