.adding {
  &-ingredient {
      &__first {
        display: grid;
        grid-gap: 20px;
        grid-template-areas:
                "title title title title"
                "cover cover icon icon"
                "cover cover icon icon"
                "name name name name"
                "extraName extraName extraName extraName"
                "calories grams grams measure"
                "cellulose proteins fats carbohydrates"
                "barcode barcode barcode barcode"
                "manufacturer manufacturer manufacturer manufacturer"
                "description description description description"
                "extraDescription extraDescription extraDescription extraDescription";
        &_title {
          grid-area: title;
        }
      }
      &__name {
        grid-area: name;
      }
      &__extraName {
        grid-area: extraName;
      }
      &__calories {
        grid-area: calories;
      }
      &__cellulose {
        grid-area: cellulose;
      }
      &__measure {
        grid-area: measure;
      }
      &__grams {
        grid-area: grams;
      }
      &__proteins {
        grid-area: proteins;
      }
      &__fats {
        grid-area: fats;
      }
      &__carbohydrates {
        grid-area: carbohydrates;
      }
      &__description {
        grid-area: description;
      }
      &__extraDescription {
        grid-area: extraDescription;
      }
      &__cover {
        grid-area: cover;
      }
      &__icon {
        grid-area: icon;
      }
      &__title {
        grid-column: 1 / -1;
      }
    &__manufacturer {
      grid-area: manufacturer;
    }
    &__barcode {
      grid-area: barcode;
    }
      &__second {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
      }
    &__label {
      grid-column: 1 / span 2;
    }
      &__third {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(7, 1fr);
      }
    }
  &-material {
    &__main {
      display: grid;
      grid-gap: 25px;
    }
  }
  &-menu {
    &__main {
      display: grid;
      grid-gap: 25px;
    }
    &__meals {
      margin-bottom: 25px;
    }
  }
  &-meals {
    &__title {
      margin-bottom: 14px;
      font-size: 1.2em;
    }
  }
}
